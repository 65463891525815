import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/Assets/css/globle.css";
import './Containers/Front/showcase_2.o/Assets/global.css'
import "./App.css";
import "../src/Assets/css/custome.css";
import "../src/Assets/css/color.css";
import "../src/Assets/css/Education/style.css";
import Home from "./Containers/Front/showcase_2.o/Home";
import React from "react";
import { Suspense } from "react";
import { Skeleton } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import Login from "./Components/Login";
import { useSelector } from "react-redux";
import { isDesktop, isMobileOnly } from "react-device-detect";
const Mainrouter = React.lazy(() => import("./mainrouter"));
const MainRequest = React.lazy(() => import("./Components/mainRequest"));
const MegaMenu = React.lazy(() => import("./Containers/Front/showcase_2.o/Header/MegaMenu"));
const Header = React.lazy(() => import("./Containers/Header/Header"));
const Footer = React.lazy(() => import("./Containers/Footer/footer"));
const App = () => {
  const [menudata, setMenudata] = useState([]);
  const login = useSelector(
    (state) => state.persistedReducer.home.logindrawer
  );
  // useLayoutEffect(() => {
  //   //---------------------------- currency api
  //   axios.get(base_url + '/common/currency')
  //     .then(res => {
  //       if (res.data.status == 1) {
  //         setCurrencydata(res.data.data);
  //         sessionStorage.getItem("bw-currency") ? sessionStorage.getItem("bw-currency") : sessionStorage.setItem("bw-currency", res.data.data[0]?.currency_code);
  //         sessionStorage.getItem("bw-currency_img") ? sessionStorage.getItem("bw-currency_img") : sessionStorage.setItem("bw-currency_img", res.data.data[0]?.country_flag);
  //         sessionStorage.getItem("bw-currencyicon") ? sessionStorage.getItem("bw-currencyicon") : sessionStorage.setItem("bw-currencyicon", res.data.data[0]?.currency_icon);

  //       } else {
  //         console.log(res.data.message)
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })

  //      //-----------------------------------menu
  //      axios
  //     .get(base_url + "/home/menu", {
  //       headers: postHeader,
  //     })
  //     .then((response) => {
  //       if (response.data.status == 1) {
  //         setMenudata(response.data.data);
  //       } else {
  //         toast.error(response.message, { autoClose: 3000 });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // }, []);

  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <>
              <Skeltonheader />
            </>
          }
        >
          {" "}
          <MainRequest />
          {/* <Header menudata = {menudata}  currencydata={currencydata} />   */}
          <MegaMenu HeaderStyle='1' />
        </Suspense>
        <div className="mainPage">
        <Switch>
          <Route exact path="/" component={Home} />
          <Suspense
            fallback={
              <>
                <Skelton />
              </>
            }
          >
            <Route path="*" exact={true} component={Mainrouter} />
            {/* <Route path="/education" component={Education} /> */}
          </Suspense>
        </Switch>
        </div>
   
        <Suspense
          fallback={
            <>
              <Skelton />
            </>
          }
        >
          <Footer />
          {login && !isDesktop ? <Login /> :""}
        </Suspense>
      </BrowserRouter>

    </>
  );
};

// skelton conponent
const Skelton = () => {
  return (
    <>
      {/* <div className="sk" style={{ margin: "15px 0px" }}>
        <Skeleton variant="text" animation="wave" />
      </div> */}
    </>
  );
};
const Skeltonheader = () => {
  return (
    <>
      <div className="skh" style={{ margin: "0px 0px 10px 0px" }}>
        <Skeleton variant="text" animation="wave" height={75} />
      </div>
    </>
  );
};
export default App;
