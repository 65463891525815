import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';
import Slider from 'react-slick';

const BannerSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
 //-----------------------------------Banner
 setLoading(true)
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setLoading(false)
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
    {!loading ? <Slider {...settings} className="rcs_hero_slider">
      {banner?.map((val, index) =>
        <div className="rcs_hero_img">
            <Image src={`${isMobileOnly ? val?.mobile_image : val?.image}`} alt="" />
  
            <div className={`${isMobile ? 'text-center' : val.text_direction_class} text-center content-inner`}>
              <h1>{(val.title)}</h1>
              <p>{(val.short_text)}</p>
              <Button onClick={() => { history.push('/' + val.btn_link) }} className="outline-base-btn large">{val.btn_text}</Button>
            </div>
        </div>
      )}
    </Slider> : <Skeleton variant="text" animation="wave"/>}
      
    </>
  )
}

export default BannerSlider;