import React from 'react'
// import Container from '../ui/Container'
import Heading from '../ui/Heading'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'

import Ring from "../../../../Assets/images/engagement.png";
import Wedding from "../../../../Assets/images/wedding.png";
import Bracelet from "../../../../Assets/images/bracelet.png";
import Earring from "../../../../Assets/images/earring.png";
import Necklace from "../../../../Assets/images/necklace.png";
import Pendant from "../../../../Assets/images/pendants.png";
import divider from '../../../../Assets/images/yellow-divider.png';

const ExploreCollection = () => {
    return (
        <section className='explore-collection section-space'>
            <Container fluid className='showcase-container'>
                <Heading>
                    <h2>Top Trending Collection</h2>
                    <Image src={divider} alt='' />
                </Heading>

                <Row>
                    <Col md={4} xs={6}>
                        <div className='item text-center'>
                            <Link to='/ringsettings' className='img-box'><Image src={Ring} alt='Ring' /></Link>
                            <Link to='/ringsettings'>Engagement Rings</Link>
                        </div>
                    </Col>
                    <Col md={4} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/wedding-rings' className='img-box'><Image src={Wedding} alt='Wedding' /></Link>
                            <Link to='/jewelry/wedding-rings'>Wedding Rings</Link>
                        </div>
                    </Col>
                    <Col md={4} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/bracelets' className='img-box'><Image src={Bracelet} alt='Bracelet' /></Link>
                            <Link to='/jewelry/bracelets'>Bracelets</Link>
                        </div>
                    </Col>
                    <Col md={4} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/necklaces' className='img-box'><Image src={Necklace} alt='Necklace' /></Link>
                            <Link to='/jewelry/necklaces'>Necklaces</Link>
                        </div>
                    </Col>
                    <Col md={4} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/earrings' className='img-box'><Image src={Earring} alt='Earring' /></Link>
                            <Link to='/jewelry/earrings'>Earrings</Link>
                        </div>
                    </Col>
                    <Col md={4} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/pendants' className='img-box'><Image src={Pendant} alt='Pendant' /></Link>
                            <Link to='/jewelry/pendants'>Pendants</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ExploreCollection