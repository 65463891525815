import React from 'react';
// import Container from '../ui/Container';

import Heading from '../ui/Heading';
import { Container } from 'react-bootstrap';

const Insta = () => {
    return (
        <section className='insta-section section-space'>
            <Container fluid className='showcase-container'>
                <Heading>
                    <h2>Social Media</h2>
                    <p>Follow us on Instagram <a href='https://www.instagram.com/sprucediamonds' className='text-dark' target='_blank'><strong>@sprucediamonds</strong></a></p>
                </Heading>
                {/* <iframe src="https://www.instagram.com/spruce_jewelers" frameborder="0" width="100%" class="insta-iframe"></iframe> */}
            </Container>
        </section>
    )
}

export default Insta;